




































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'
import { uniqBy } from 'lodash'

// mixins
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { ManagerCoursesTreeSelectGetParams, NameValueChildrenResource, NameValueResource } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import SystemModule from '@/store/modules/system'

@Component({
  inheritAttrs: false,
})
export default class CourseMultipleSearch extends Mixins(DetectSafariMixin, NotifyMixin) {
  @Prop({ default: '' })
  private value!: any

  @Prop({ default: () => ([]) })
  private options!: NameValueResource[]

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  private isLoading = false
  private search: string | null = null
  private innerValue: number[] = []
  private innerList: NameValueResource[] = []

  private autocomplete: HTMLElement | null = null
  private positionScrollY = 0

  private mounted () {
    this.innerValue = this.value
    this.innerList = this.options
  }

  private handleInput(value: number[]) {
    this.innerValue = value

    this.innerList = this.innerList.map(item => {
      if (!this.innerValue.length) {
        return {
          ...item,
          disabled: false,
        }
      }
      if (!item.value) {
        return {
          ...item,
          disabled: !this.innerValue.includes(0),
        }
      }
      return {
        ...item,
        disabled: this.innerValue.includes(0),
      }
    })

    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  private handleFocus() {
    setTimeout(() => {
      this.$bus.$emit('getPositionScrollY')
      this.positionScrollY = SystemModule.scrollY
      this.autocomplete = document.querySelector('.menuable__content__active.v-autocomplete__content') as HTMLElement
      this.$bus.$on('handleScroll', this.handleScroll)
    })
  }

  private handleBlur() {
    this.autocomplete = null
    this.positionScrollY = 0
    this.$bus.$off('handleScroll', this.handleScroll as any)
  }

  private handleScroll(height: number) {
    if (this.autocomplete) {
      const changeScroll = height - this.positionScrollY
      const autocompleteTop = +this.autocomplete.style.top.replace('px', '')
      this.autocomplete.style.top = `${autocompleteTop - changeScroll}px`
      this.positionScrollY = height
    }
  }

  private deleteChip(item: any) {
    if (Array.isArray(this.innerValue)) {
      for (let i = 0; i < this.innerValue.length; i++) {
        if (this.innerValue[i] === item.value) {
          this.innerValue.splice(i, 1)
        }
      }
    }
    this.handleInput(this.innerValue)
  }

  @Watch('options')
  private watchOptions(value: NameValueResource[]) {
    this.innerList = uniqBy([...this.innerList, ...value], 'value')
  }

  @Bind
  @Debounce(500)
  @Watch('search')
  private watchValue(value: string) {
    if (this.isLoading) return

    this.isLoading = true

    DictionaryModule.searchCoursesTree({
      groupReturnId: undefined,
      query: value,
      subjectId: undefined,
    } as ManagerCoursesTreeSelectGetParams)
      .then((response: NameValueChildrenResource[]) => {
        const list = response.map(item => ({
          ...item,
          disabled: this.innerValue.includes(0),
        }))
        this.innerList = uniqBy([...this.innerList, ...list], 'value')
      })
      .catch(this.notifyError)
      .finally(() => (this.isLoading = false))
  }
}
